<template>
  <div class="box">
      <img
          src="https://middle-ground.shulanhealth.com/22022220908124748.png"
          alt=""
          class="bg-title"
      />
      <div class="box-margin">
          <div class="content-box" v-if="failEdit">
              <!-- <div class="box-item">
                  <div>车主姓名 <span style="color: red">*</span></div>
                  <div>
                      <input
                          type="text"
                          class="input-gh"
                          v-model="passengerName"
                          @input="formatRealName"
                          placeholder="请输入"
                      />
                  </div>
              </div> -->
              <!-- <div class="box-item">
                  <div>联系电话 <span style="color: red">*</span></div>
                  <div>
                      <input
                          type="text"
                          class="input-gh"
                          v-model="passengerMobile"
                          maxlength="11"
                          placeholder="请输入"
                      />
                  </div>
              </div> -->
              <div class="box-item">
                  <div>可乘坐人数 <span style="color: red">*</span></div>
                  <div>
                      <input
                          type="text"
                          class="input-gh"
                          v-model="passengerNum"
                          maxlength="2"
                          placeholder="请输入"
                      />
                  </div>
              </div>
              <div class="box-item">
                  <div>起点 <span style="color: red">*</span></div>
                  <div @click="selStartAddress">
                      <div
                          :style="startAddress==null? 'color:#D2C5C0' : 'color:#000'"
                          class="input-gh"
                      >{{!startAddress ? '请选择' : startAddress.name}}</div>
                  </div>
              </div>
              <div class="box-item">
                  <div>终点 <span style="color: red">*</span></div>
                  <div @click="selEndAddress">
                    <div
                          :style="endAddress==null? 'color:#D2C5C0' : 'color:#000'"
                          class="input-gh"
                      >{{endAddress==null? '请选择' : endAddress.name}}</div>
                  </div>
              </div>
              <div class="box-item" style="height: auto;padding: 10px 0;">
                  <div>途经点 <span style="color: red"></span></div>
                  <div @click="selTjAddress">
                    <div
                          :style="tjdAddress==null? 'color:#D2C5C0' : 'color:#000'"
                          class="input-gh"
                          v-if="tjdAddress==null"
                      >请选择</div>
                    <div
                          v-else
                          class="input-gh"
                      >
                        <div v-for="(item,index) in tjdAddress" :key="index">
                            <van-tag closeable type="success" @close="closeTag(index)">{{index+1}} : {{item.name}}</van-tag>
                        </div>  
                    </div>
                  </div>
              </div>
              <div class="box-item">
                  <div>出发时间 <span style="color: red">*</span></div>
                  <div style="cursor: pointer" @click="selQwrq">
                      <div
                          class="title"
                          :style="qwrqText !== '' ? 'color:#272929;' : ''"
                      >
                          {{
                              qwrqText == "" ? "请选择" : qwrqText
                          }}
                      </div>
                      <img
                          src="http://middle-ground.shulanhealth.com/f-fqxq-1.png"
                          alt=""
                          class="btm-icon"
                      />
                  </div>
              </div>
              <div class="box-item1">
                  <div>有效期</div>
                  <div style="margin-top:10px;">
                      <div class="user-type" @click="isYouXq(1)">
                          <img
                              :src="
                                  islong == 1
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  islong == 1
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              单次
                          </div>
                      </div>
                      <div class="user-type" @click="isYouXq(2)">
                          <img
                              :src="
                                  islong == 2
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  islong == 2
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              长期
                          </div>
                      </div>
                  </div>
              </div>
              <!-- <div class="box-item1">
                  <div>是否有大件行李</div>
                  <div style="margin-top:10px;">
                      <div class="user-type" @click="isJzxq(1)">
                          <img
                              :src="
                                  jzxq == 1
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  jzxq == 1
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              无
                          </div>
                      </div>
                      <div class="user-type" @click="isJzxq(2)">
                          <img
                              :src="
                                  jzxq == 2
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  jzxq == 2
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              有
                          </div>
                      </div>
                  </div>
              </div>
              <div class="box-item1">
                  <div>是否拼车</div>
                  <div style="margin-top:10px;">
                      <div class="user-type" @click="isCarpooling(1)">
                          <img
                              :src="
                                  carpooling == 1
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  carpooling == 1
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              是
                          </div>
                      </div>
                      <div class="user-type" @click="isCarpooling(2)">
                          <img
                              :src="
                                  carpooling == 2
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  carpooling == 2
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              否
                          </div>
                      </div>
                  </div>
              </div> -->
              <div class="box-item">
                  <div>车费 <span style="color: red">*</span></div>
                  <div>
                      <input
                      style="width:100%;"
                          type="number"
                          class="input-gh"
                          v-model="fee"
                          maxlength="3"
                          placeholder="如不填写此项，系统默认自行沟通费用"
                      />
                  </div>
              </div>
              <div class="box-item2">
                  <div style="font-size:16px;">备注 <span style="color: red"></span></div>
                  <div>
                      <textarea
                          type="text"
                          class="input-xq"
                          v-model="remarks"
                          placeholder="请输入"
                      />
                  </div>
              </div>

              <div class="box-item21" style="height:25px;">
                <van-checkbox v-model="iAgree" shape="square" icon-size="16px" style="font-size:12px;">我已阅读并同意<span class="mzesm" @click="mzsming">《约车免责声明》</span></van-checkbox>
              </div>
              <div
                    v-if="iAgree && !isLoading"
                  class="share-url"
                  @click="submitOrEditData"
              >
                 提交行程
              </div>
              <div
                    v-else
                  class="share-url1"
              >
                 提交行程
              </div>
              <!-- <div class="share-url" v-if="isLoading">正在提交...</div> -->
          </div>
          <van-empty
              style="height: 80%"
              description="当前链接已被填写过了哟~"
              v-else
          />
          <div style="height: 15px"></div>
      </div>
      <van-calendar
            v-model="showCalendar"
            :show-confirm="false"
            :title="'请选择出行日期'"
            :color="'#079bab'"
            @confirm="selectCalendar"
        />
      <van-popup v-model="showZjlxPopup" position="bottom">
          <div style="width: 100%">
              <van-picker
                  title="请选择"
                  show-toolbar
                  :columns="zjlxCom"
                  @confirm="onConfirm"
                  @cancel="onCancel"
              />
          </div>
      </van-popup>
      <van-popup v-model="showQwyyPopup" position="bottom">
          <div style="width: 100%">
              <van-picker
                  title="请选择"
                  show-toolbar
                  :columns="jzyyCom"
                  @confirm="onConfirm1"
                  @cancel="onCancel1"
              />
          </div>
      </van-popup>
      <div class="loading" v-show="isLoading">
          <van-loading text-color="#1DA9A4" color="#1DA9A4" vertical
              >发布中...</van-loading
          >
      </div>
      <van-popup v-model="showQwrqPopup" round position="bottom">
          <div style="width: 100%">
              <van-picker
                  title="请选择出发时间"
                  show-toolbar
                  :columns="columns"
                  @confirm="onsjdConfirm"
                  @cancel="onsjdCancel"
              />
          </div>
      </van-popup>
      <van-popup v-model="showMapPopup" round position="bottom" :style="{ height: '600px' }">
          <div style="width: 100%;position: relative;">
            <div id="container" :style="'height:' + pmHeight + 'px;'"></div>
            <div class="zzgh" v-if="showLxgh">路线规划中...</div>
            <div class="search-box1">
                <div class="search-box-one">
                <van-icon class="search-icon" name="search" />
                <input
                    class="input-sty"
                    type="text"
                    v-model="keyword"
                    @input="inputKey"
                    placeholder="输入目的地"
                />
                
                </div>
                <div @click="cencleSel">取消</div>
            </div>
            <div :style="pointInfo != null ? 'height:220px;' : 'height:320px;'" class="gdt" v-if="searchList.length!=0">
            <div v-for="(item,index) in searchList" :key="index" class="address-item" @click="selPoint(item)">
                <div style="width:78%;display: flex;align-items: center;">
                <van-icon name="location-o" style="font-size:18px;"/>
                <div style="margin-left:5px;width:100%;">
                    <div style="font-size: 15px;font-weight:700;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
                    {{item.name}}
                    </div>
                    <div style="font-size:12px;margin-top:2px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
                        {{item.district}}{{item.address}}
                    </div>
                </div>
                </div>
            </div>
            
            </div>
            <div style="width:100%;border-radius: 10px;margin-top: 5px;padding: 5px 10px;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);position: fixed;bottom: 0;left: 0;background: #fff;box-sizing: border-box;" v-if="pointInfo!=null">
                    <!-- <div style="width:95%;margin-left:5%; height:1px;background:#f2f2f2;margin-top: 5px;"></div> -->
                    <div style="background: #FAFAFB;height: 30px;line-height: 30px;padding: 0 10px;border-radius: 5px;" v-if="pointType!=3">
                        <div style="display:flex;margin-top: 5px;">
                            <div><van-icon name="location-o" style="font-size:14px;margin-right: 3px;"/>{{pointType == 1 ? '起点：' : (pointType == 2 ? '终点：' : '途经点：')}}</div>
                            <div>{{pointInfo.name}}</div>
                        </div>
                    </div>
                    <div class="simple-page" v-if="pointType==3">
                        <draggable v-model="tjd" group="people" animation="300" @start="onStart" @end="onEnd"> 
                            <transition-group> 
                                <div style="background: #FAFAFB;padding: 5px 10px;border-radius: 5px;margin-top: 5px;" v-for="(ite,index) in tjd" :key="ite.id">
                                    <div style="display: flex;align-items: center;justify-content: space-between;">
                                        <div>
                                        <van-icon name="location-o" style="font-size:14px;margin-right: 3px;"/>
                                        {{pointType == 1 ? '起点：' : (pointType == 2 ? '终点：' : '途经点' + (index+1)+ '：')}}{{ite.name}} 
                                        </div>
                                        <span>
                                        <img style="width:15px;margin-left:20px;" src="./comm/tuodong-icon.png" alt="">
                                        </span>
                                    </div>
                                </div>
                            </transition-group> 
                        </draggable>
                    </div>
                    <div class="next" @click="surePoint" v-if="pointInfo!=null || tjd.length!=0">
                        确定
                    </div>
                </div>
                
            <!-- <div id="container" style="'height:600px;position: absolute;left: 0;bottom: 0;'"></div> -->
          </div>
      </van-popup>
      <van-dialog v-model="showDialog" title="请核对您的信息" show-cancel-button :cancel-button-text="hisId == '' ? '去修改' : '放弃修改'" confirm-button-text='提交' @confirm="confirmJzxq">
          <div class="info-box">
             奥术大师多
          </div>
      </van-dialog>
  </div>
</template>
<script>
  import { passenger } from "@/api";
let timers = null;
import AMap from "AMap"; // 引入高德地图
var driving;
// 创建一个 Marker 实例：
var marker
import { user } from "@/api";
import Config from "@/config";
let lsDate = "";
import draggable from "vuedraggable";
import {
  Popup,
  Picker,
  ImagePreview,
  Loading,
  Toast,
  Empty,
  Dialog,
  Checkbox
} from "vant";
// import { compressImage } from "@/utils/CompressImageUtils";
export default {
  components: {
    draggable,
      VanLoading: Loading,
      VanPopup: Popup,
      VanPicker: Picker,
      VanEmpty: Empty,
      VanCheckbox: Checkbox,
  },
  data() {
      return {
          showLxgh: false,
          iAgree:false,
          passengerName: "",
          passengerMobile: "",
          passengerNum: "",
          fee: "",
          remarks: "",
          tripDate: "",
          tripTime: "",
          carpooling: 1,
          islong: 1,
          showDialog: false,
          showCalendar: false,
          showMapPopup:false,
          showMapTjdPopup: false,
          qwrqText: "",
          minDate: "",
          maxDate: "",
          columns: [
              "05:00-05:30",
              "05:30-06:00",
              "06:30-07:00",
              "07:30-08:00",
              "08:30-09:00",
              "09:30-10:00",
              "10:30-11:00",
              "11:30-12:00",
              "12:30-13:00",
              "13:30-14:00",
              "14:30-15:00",
              "15:30-16:00",
              "16:30-17:00",
              "17:30-18:00",
              "18:30-19:00",
              "19:30-20:00",
              "20:30-21:00",
              "21:30-22:00"
          ],
          showQwrqPopup: false,
          status: "",
          isSel: 2,
          isVip: true,
          hisId: "",
          isLoading: false,
          sex: 1, // 性别 1男2女
          realName: "", // 真实名称
          idCardNumber: "", // 证件号码
          mobile: "", // 联系电话
          age: "", // 年龄
          jzxq: 1, // 就诊需求
          zjlx: "", // 证件类型
          zjlxObj: {}, // 证件类型
          qwyy: "", // 期望医院
          qwyyObj: {}, // 期望医院
          zjlxCom: [],
          jzyyCom: [],
          slhCom: {},
          jyjcbgList: [],
          jyjcbgStringList: [],
          showZjlxPopup: false,
          showQwyyPopup: false,
          qywxId: "",
          failEdit: true, //是否可以填写
          isCurrentTime: false,
          keyword: "",
          searchList:[],
          startAddress: null,
          endAddress: null,
          tjdAddress: null,
          pointInfo: null,
          tjd: [],
          pointType: 1,
          pmHeight: 500,
          addressInfo: null,
          isAdd: 'ADD'
      };
  },
  mounted() {
      this.isAdd = this.$route.query.isAdd
      this.isLoading = false;
      this.pmHeight = 600 - 400;
      let date = new Date();
      this.minDate = new Date(date);
      this.maxDate = new Date(this.getNextDate(date, 13));
      document.title = "填写行程";
      this.isCurrentTime = this.time_range("08:00", "17:00");
      if(this.$route.query.isAdd == 'EDIT') {
        this.getMyInfo()
      }
  },
  methods: {
    isYouXq(e) {
        this.islong = e
    },
    mzsming() {
        this.$router.push({
            name: 'mzsm'
        })
    },
    closeTag(index) {
      console.log(index)
      this.tjdAddress.splice(index,1)
      if(this.tjdAddress.length == 0) {
        this.tjdAddress = null
      }
      console.log(this.tjdAddress)
    },
    // 开始拖拽事件
    onStart() {
        // to do
    },
    // 拖拽结束事件
    onEnd() {
        // to do
        this.initgHMap()
    },
    getMyInfo() {
        let params = {
            id: this.$route.query.id
        }
        passenger.getMyTripById(params).then(res =>{
            if(res.data.code == 200) {
                this.passengerName = res.data.data.passengerName
                this.passengerMobile = res.data.data.passengerMobile
                this.passengerNum = res.data.data.passengerNum
                this.startAddress = {
                    name: res.data.data.startAddress,
                    district: res.data.data.startDistrict,
                    location: [res.data.data.startLng,res.data.data.startLat]
                }
                this.endAddress = {
                    name: res.data.data.endAddress,
                    district: res.data.data.endDistrict,
                    location: [res.data.data.endLng,res.data.data.endLat]
                },
                this.jzxq = res.data.data.luggage
                this.fee = res.data.data.fee
                this.remarks = res.data.data.remarks
                this.tripDate = res.data.data.tripDate
                this.tripTime = res.data.data.tripTime
                this.qwrqText = res.data.data.tripDate + " " + res.data.data.tripTime;
                this.carpooling = res.data.data.carpooling
                this.islong = res.data.data.islong,
                this.tjdAddress = (res.data.data.tjd == null || res.data.data.tjd =='') ? null : JSON.parse(res.data.data.tjd)
            }
        })
    },
    cencleSel() {
        this.showMapPopup = false
    },
    cencleSel1() {
        this.showMapTjdPopup = false
    },
    surePoint() {
        this.keyword = ""
        if(this.pointType == 1) {
            this.startAddress = this.pointInfo
        } else if(this.pointType == 2) {
            this.endAddress = this.pointInfo
        } else {
            this.tjdAddress = this.tjd
        }
        this.showMapPopup = false
        this.showMapTjdPopup = false
    },
    // onDrop(dropResult) {
    //   this.tjd = applyDrag(this.tjd, dropResult);
    // //   this.initgHMap()
    // },
    initgHMap() {
      var _this = this;
      this.showLxgh = true
      const dom = document.getElementById("container");
      this.map = new AMap.Map(dom, {
        resizeEnable: true,
        zoom: 15,
        center: [120.246232, 29.986425],
        showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
      });
      AMap.plugin("AMap.AutoComplete", function () {
        var autoOptions = {
          //city 限定城市，默认全国
          city: "全国",
        };
        // 实例化AutoComplete
        _this.autoComplete = new AMap.Autocomplete(autoOptions);
      });
      // // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
      // AMap.plugin('AMap.ToolBar',function(){//异步加载插件
      //     var toolbar = new AMap.ToolBar();
      //     _this.map.addControl(toolbar);
      // });
      if (driving) {
        driving.clear();
      }
      if(_this.startAddress != null && _this.endAddress != null) {
      AMap.plugin("AMap.Driving", function () {
        driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          // map 指定将路线规划方案绘制到对应的AMap.Map对象上
          map: _this.map,
          // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
          // panel: 'container'
        });
        
        var startLngLat = [_this.startAddress.location.length == 2 ? _this.startAddress.location[0] : _this.startAddress.location.lng, _this.startAddress.location.length == 2 ? _this.startAddress.location[1] : _this.startAddress.location.lat];
        
        var endLngLat = [_this.endAddress.location.length == 2 ? _this.endAddress.location[0] : _this.endAddress.location.lng, _this.endAddress.location.length == 2 ? _this.endAddress.location[1] : _this.endAddress.location.lat];
        // var endLngLat = [_this.endAddress.location[0], _this.endAddress.location[1]];

        console.log(_this.startAddress,'_this.startAddress');
        console.log(_this.endAddress,'_this.endAddress');
        let ls = []
        console.log(_this.tjd,'_this.tjd');
        if(_this.tjd.length!=0) {
          for(let i in _this.tjd) {
            if(_this.tjd[i].location!=undefined && _this.tjd[i].location!="" && _this.tjd[i].location!=null) {
                if(_this.tjd[i].location.length == 2) {
                    ls.push(new AMap.LngLat(_this.tjd[i].location[0], _this.tjd[i].location[1]))
                } else {
                    ls.push(new AMap.LngLat(_this.tjd[i].location.lng, _this.tjd[i].location.lat))
                }
            }
          }
        }
        
        var opts = {
          waypoints: ls
        }

        console.log(startLngLat);
        console.log(endLngLat);
        driving.search(startLngLat, endLngLat,  opts, function (status, result) {
          // 未出错时，result即是对应的路线规划方案
          _this.showLxgh = false
          console.log(status);
          console.log(result);
          _this.drevingInfo = result;
        });
        // _this.showPopup = false
      });
    } else {
        _this.showLxgh = false
    }
    },
    handleEvents(e) {
        this.pointType = JSON.parse(e).type
        if(JSON.parse(e).type == 1) {
            this.startAddress = JSON.parse(e).info
        } else if(JSON.parse(e).type == 2) {
            this.endAddress = JSON.parse(e).info
        } else {
            this.tjdAddress = JSON.parse(e).info
        }
    },
    selStartAddress() {
        this.pointType = 1
        this.showMapPopup = true
        setTimeout(()=>{
        this.initgHMap()
      },500)
    },
    selEndAddress() {
        this.pointType = 2
        this.showMapPopup = true
        setTimeout(()=>{
        this.initgHMap()
      },500)
    },
    selTjAddress() {
        this.pointType = 3
        this.showMapPopup = true
        setTimeout(()=>{
            this.initgHMap()
        },500)
    },
    inputKey() {
      this.search();
    },
    selPoint(e) {
      var _this = this;
      _this.pointInfo = e
      if(this.pointType == 3) {
        this.tjd.push(e)
        this.initgHMap()
      } else {
       console.log(this.tjd,'this.tjd')
      _this.pointInfo = e
    //   this.pmHeight =
    //   (window.innerHeight ||
    //   document.documentElement.clientHeight ||
    //   document.body.clientHeight) - 198;
    //   _this.keyword = ""
    //   _this.searchList = []
      if(marker) {
        _this.map.remove(marker);
      }
      const dom = document.getElementById("container");
      this.map = new AMap.Map(dom, {
        resizeEnable: true,
        zoom: 16,
        center: [e.location.lng, e.location.lat],
        showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
      });
      // 创建一个 Marker 实例：
      marker = new AMap.Marker({
          position: new AMap.LngLat(e.location.lng, e.location.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          offset: new AMap.Pixel(-10, -10),
          title: '北京',
          // icon: icons
          // offset:new AMap.Pixel(-10,-20)
      });

      // 将创建的点标记添加到已有的地图实例：
      _this.map.add(marker);
      
      }
      _this.showPopup = false

    },
    search() {
      var _this = this;
      clearTimeout(timers)
      timers = setTimeout(() => {
          this.autoComplete.search(this.keyword, function (status, result) {
          // 搜索成功时，result即是对应的匹配数据
          console.log(status);
          console.log(result);
          _this.searchList = result.tips;
        });
      }, 300);
    },
      formatRealName() {
          this.passengerName = this.deleteNum(this.passengerName);
      },
      deleteNum(str) {
          let reg = /[0-9]+/g;
          let str1 = str.replace(reg, "");
          return str1;
      },
      onsjdConfirm(value) {
          this.qwrqText = lsDate + " " + value;
          this.tripDate = lsDate
          this.tripTime = value
          this.showQwrqPopup = false;
      },
      onsjdCancel() {
          this.showQwrqPopup = false;
          this.showCalendar = true;
          // this.qwrqText = ""
      },
      selectCalendar(e) {
          lsDate = this.getNextDate(e, 0);
          this.showCalendar = false;
          this.showQwrqPopup = true;
      },
      selQwrq() {
          this.showCalendar = true;
      },
      getNextDate(date, day) {
          var dd = new Date(date);
          dd.setDate(dd.getDate() + day);
          var y = dd.getFullYear();
          var m =
              dd.getMonth() + 1 < 10
                  ? "0" + (dd.getMonth() + 1)
                  : dd.getMonth() + 1;
          var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
          return y + "-" + m + "-" + d;
      },
      time_range(beginTime, endTime) {
          var strb = beginTime.split(":");

          if (strb.length != 2) {
              return false;
          }

          var stre = endTime.split(":");
          if (stre.length != 2) {
              return false;
          }

          var b = new Date();
          var e = new Date();
          var n = new Date();

          b.setHours(strb[0]);
          b.setMinutes(strb[1]);
          e.setHours(stre[0]);
          e.setMinutes(stre[1]);

          if (
              n.getTime() - b.getTime() > 0 &&
              n.getTime() - e.getTime() < 0
          ) {
              return true;
          } else {
              return false;
          }
      },
      jsidCard() {
          if (
              this.idCardNumber.length == 18 ||
              this.idCardNumber.length == 15
          ) {
              let ageAndsex = this.analyzeIDCard(
                  this.idCardNumber.length,
                  this.idCardNumber
              );
              this.age = ageAndsex.age;
              this.sex = ageAndsex.sex;
          }
      },
      getWxUserInfo() {
          user.wxUserInfo().then((res) => {
              if (res.data.code == 200) {
                  if(res.data.result.name!=null) {
                      this.realName = res.data.result.name
                      this.zjlxObj = {
                              text: '居民身份证',
                              title: '居民身份证',
                              value: 1,
                      }
                      this.idCardNumber = res.data.result.certificateNumber
                      this.mobile = res.data.result.phone
                      this.jsidCard()
                  }
              }
              this.isLoading = false;
          });
      },
      getImgList(e) {
          let rtList = [];
          let imgs = e.split(",");
          for (let i in imgs) {
              rtList.push(Config.resource.base + imgs[i]);
          }
          return rtList;
      },
      getImgList1(e) {
          let rtList = [];
          let imgs = e.split(",");
          for (let i in imgs) {
              rtList.push(imgs[i]);
          }
          return rtList;
      },
      getzjlx(e) {
          for (let i in this.zjlxCom) {
              if (e == this.zjlxCom[i].value) {
                  return this.zjlxCom[i];
              }
          }
      },
      getqwyy(e) {
          for (let i in this.allQwyyCom) {
              if (e == this.allQwyyCom[i].value) {
                  return this.allQwyyCom[i];
              }
          }
      },
      submitOrEditData() {
        if(this.isAdd == 'EDIT') {
            this.editTrip()
        } else {
            this.addTrip()
        }
      },
      editTrip() {
        if(this.passengerNum == '') {
            Toast.fail('请输入车主人数');
            return
        }
        if(this.startAddress == null) {
            Toast.fail('请选择起点');
            return
        }
        if(this.endAddress == null) {
            Toast.fail('请选择终点');
            return
        }
        if(this.tripDate == '' && this.tripTime == '') {
            Toast.fail('请选择出发时间');
            return
        }
        this.isLoading = true
        let params = {
          id:  this.$route.query.id,
          passengerName: this.passengerName,
          passengerNum: this.passengerNum,
          startAddress: this.startAddress.name,
          endAddress: this.endAddress.name,
          startDistrict: this.startAddress.district,
          endDistrict: this.endAddress.district,
          luggage: this.jzxq,
          fee: this.fee,
          startLng: this.startAddress.location.length == 2 ? this.startAddress.location[0] : this.startAddress.location.lng,
          startLat: this.startAddress.location.length == 2 ? this.startAddress.location[1] : this.startAddress.location.lat,
          endLng: this.endAddress.location.length == 2 ? this.endAddress.location[0] : this.endAddress.location.lng,
          endLat: this.endAddress.location.length == 2 ? this.endAddress.location[1] : this.endAddress.location.lat,
          remarks: this.remarks,
          tripDate: this.tripDate,
          tripTime: this.tripTime,
          carpooling: this.carpooling,
          islong: this.islong,
          tjd: this.tjdAddress!=null ? JSON.stringify(this.tjdAddress) : null,

        }
        console.log(params,2221)
        passenger.updatePassengerTrip(params).then(res=>{
          if(res.data.code == 200) {
            this.isLoading = false
            Dialog.alert({
                title: 'Hi',
                message: '修改成功~',
                }).then(() => {
                    this.$router.back()
                });
          }
        })
      },
      addTrip() {
        if(this.passengerNum == '') {
            Toast.fail('请输入车主人数');
            return
        }
        if(this.startAddress == null) {
            Toast.fail('请选择起点');
            return
        }
        if(this.endAddress == null) {
            Toast.fail('请选择终点');
            return
        }
        if(this.tripDate == '' && this.tripTime == '') {
            Toast.fail('请选择出发时间');
            return
        }
        this.isLoading = true
        let params = {
          passengerName: this.passengerName,
          passengerNum: this.passengerNum,
          startAddress: this.startAddress.name,
          endAddress: this.endAddress.name,
          startDistrict: this.startAddress.district,
          endDistrict: this.endAddress.district,
          luggage: this.jzxq,
          fee: this.fee,
          startLng: this.startAddress.location.length == 2 ? this.startAddress.location[0] : this.startAddress.location.lng,
          startLat: this.startAddress.location.length == 2 ? this.startAddress.location[1] : this.startAddress.location.lat,
          endLng: this.endAddress.location.length == 2 ? this.endAddress.location[0] : this.endAddress.location.lng,
          endLat: this.endAddress.location.length == 2 ? this.endAddress.location[1] : this.endAddress.location.lat,
          remarks: this.remarks,
          tripDate: this.tripDate,
          tripTime: this.tripTime,
          carpooling: this.carpooling,
          tjd: this.tjdAddress!=null ? JSON.stringify(this.tjdAddress) : null,

        }
        passenger.addPassengerTrip(params).then(res=>{
          if(res.data.code == 200) {
            this.isLoading = false
            Dialog.alert({
                title: 'Hi',
                message: '发布成功~',
                }).then(() => {
                // on close
                    this.passengerName = ''
                    this.passengerMobile = ''
                    this.passengerNum = ''
                    this.startAddress = null
                    this.endAddress = null
                    this.jzxq = 1
                    this.fee = ''
                    this.remarks = ''
                    this.tripDate = ''
                    this.tripTime = ''
                    this.carpooling = 1
                    this.tjdAddress = null
                    this.$router.back()
                });
          }
        })
      },
      confirmJzxq() {
          if (this.hisId == "") {
              this.submitData();
          } else {
              this.editData();
          }
          this.showDialog = false
      },
      submitData() {
          
          this.isLoading = true;
          let params = {
              age: this.age,
              certificateNumber: this.idCardNumber,
              certificateType: this.zjlxObj.value,
              createLinksId: this.qywxId,
              expectedHospital: this.qwyyObj.value,
              inspectionReport: this.jyjcbgStringList.toString(),
              medicalNeeds: this.remarks,
              jzxq: this.jzxq,
              name: this.realName,
              phone: this.mobile,
              sex: this.sex,
              expectedTime: this.qwrqText,
          };
          user.wxSubCon(params).then((res) => {
              if (res.data.code == 200) {
                  this.hisId = res.data.result.id;
                  this.isLoading = false;
                  Dialog({
                      title: this.isCurrentTime
                          ? ""
                          : "您的就诊需求已提交成功啦~",
                      message: this.isCurrentTime
                          ? "您的就诊需求已提交成功啦~"
                          : "(小桥已经下班咯，上班后将与您联系)",
                      confirmButtonText: "知道了",
                      confirmButtonColor: "#1DA9A4",
                  });
              } else {
                  this.isLoading = false;
                  Toast.fail(res.data.message);
              }
          });
      },
      editData() {
          if (this.realName == "") {
              Toast.fail("请填写真实姓名");
              return;
          }
          if (JSON.stringify(this.zjlxObj) == "{}") {
              Toast.fail("请选择证件类型");
              return;
          }
          if (this.idCardNumber == "") {
              Toast.fail("请填写证件号码");
              return;
          }
          if (this.mobile == "") {
              Toast.fail("请填写联系电话");
              return;
          }
          if (JSON.stringify(this.qwyyObj) == "{}") {
              Toast.fail("请选择期望医院");
              return;
          }
          if (this.qwrqText == "") {
              Toast.fail("请选择期望就诊日期");
              return;
          }
          this.isLoading = true;
          let params = {
              age: this.age,
              id: this.hisId,
              certificateNumber: this.idCardNumber,
              certificateType: this.zjlxObj.value,
              createLinksId: this.qywxId,
              expectedHospital: this.qwyyObj.value,
              inspectionReport: this.jyjcbgStringList.toString(),
              medicalNeeds: this.remarks,
              jzxq: this.jzxq,
              name: this.realName,
              phone: this.mobile,
              sex: this.sex,
              expectedTime: this.qwrqText,
          };
          user.wxEditCon(params).then((res) => {
              if (res.data.code == 200) {
                  this.isLoading = false;
                  Dialog({
                      title: this.isCurrentTime
                          ? ""
                          : "您的就诊需求修改成功啦~",
                      message: this.isCurrentTime
                          ? "您的就诊需求修改成功啦~"
                          : "(小桥已经下班咯，上班后将与您联系)",
                      confirmButtonText: "知道了",
                      confirmButtonColor: "#1DA9A4",
                  });
              } else {
                  this.isLoading = false;
                  Toast.fail(res.data.message);
              }
          });
      },
      analyzeIDCard(val, idcar) {
          let iden = idcar;
          let sex = null;
          let myDate = new Date();
          let month = myDate.getMonth() + 1;
          let day = myDate.getDate();
          let age = 0;
          let sumData = {};
          if (val === 18) {
              age = myDate.getFullYear() - iden.substring(6, 10) - 1;
              sex = iden.substring(16, 17);

              if (
                  iden.substring(10, 12) < month ||
                  (iden.substring(10, 12) == month &&
                      iden.substring(12, 14) <= day)
              )
                  age++;
          }
          if (val === 15) {
              age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
              sex = iden.substring(14, 15);

              if (
                  iden.substring(8, 10) < month ||
                  (iden.substring(8, 10) == month &&
                      iden.substring(10, 12) <= day)
              )
                  age++;
          }

          // if (sex % 2 === 0) sex = "0";
          // else sex = "1";
          sumData = {
              age: age,
              sex: sex,
          };
          return sumData;
      },
      // 获取下拉框数据
      getidCardByCode() {
          this.zjlxCom = [];
          user.getMasterData("H20503")
              .then((res) => {
                  for (let i in res.data.result) {
                      this.zjlxCom.push({
                          text: res.data.result[i].nationalStandardMeaning,
                          title: res.data.result[i].nationalStandardMeaning,
                          value: res.data.result[i].nationalStandardValues,
                      });
                  }
                  // this.zjlxCom = lsList
                  this.isLoading = false;
                  this.showZjlxPopup = true;
              })
              .catch(() => {
                  this.isLoading = false;
              });
      },
      // 获取下拉框数据
      getQwHospitalByCode() {
          let lsList = [];
          user.getMasterData("Z90010")
              .then((res) => {
                  for (let i in res.data.result) {
                      lsList.push({
                          text: res.data.result[i].nationalStandardMeaning,
                          title: res.data.result[i].nationalStandardMeaning,
                          value: res.data.result[i].nationalStandardValues,
                      });
                  }
                  lsList.splice(2, 1);
                  this.jzyyCom = lsList;
                  this.slhCom = lsList[2];
                  this.isLoading = false;
                  this.showQwyyPopup = true;
              })
              .catch(() => {
                  this.isLoading = false;
              });
      },
      upLoadImg(e) {
          let file = e.target.files[0];
          let fd = new FormData();
          fd.append("file", file);
          this.uploadImg(fd);
          // console.log(file);
          // this.compressImg(file);
          // let fd = new FormData()
          // fd.append('file', file)
          // this.uploadFile(fd, s)
      },
      uploadImg(fd) {
          // let params = {
          //     file: fd,
          //     biz: 'temp'
          // };
          this.isLoading = true;
          user.upLoadFile(fd)
              .then((res) => {
                  this.jyjcbgList.push(
                      Config.resource.base + res.data.message
                  );
                  this.jyjcbgStringList.push(res.data.message);
                  this.isLoading = false;
              })
              .catch(() => {
                  this.isLoading = false;
              });
      },
      getQueryString: function (name) {
          let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
          let r = window.location.search.substr(1).match(reg);
          if (r != null) {
              return unescape(decodeURI(r[2]));
          }
      },
      delPic(index) {
          this.jyjcbgList.splice(index, 1);
          this.jyjcbgStringList.splice(index, 1);
      },
      showPic(index) {
          ImagePreview(this.jyjcbgList, index);
      },
      gzxqdClick(e) {
          this.$router.push({ name: "gzxqd", query: e });
      },
      isUserSex(e) {
          this.sex = e;
      },
      isJzxq(e) {
          this.jzxq = e;
      },
      isCarpooling(e) {
          this.carpooling = e;
      },
      
      parentData(e) {
          this.isSel = e;
      },
      selZjlx() {
          this.isLoading = true;
          this.getidCardByCode();
      },
      selQwyy() {
          this.isLoading = true;
          this.getQwHospitalByCode();
      },
      onConfirm(value) {
          this.zjlx = value.text;
          this.zjlxObj = value;
          this.showZjlxPopup = false;
      },
      onCancel() {
          this.showZjlxPopup = false;
      },
      onConfirm1(value) {
          this.qwyy = value.text;
          this.qwyyObj = value;
          this.showQwyyPopup = false;
      },
      onCancel1() {
          this.showQwyyPopup = false;
      },
  },
};
</script>
<style scoped>
.mzesm {
    font-size: 12px;
    color: #2D83E8;text-decoration:underline;
}
/deep/ .van-calendar__confirm {
  height: 45px !important;
  font-size: 16px;
}
.zzgh {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 18px;
}
.search-box1 {
  /* position: absolute;
  left: 0;
  top:0; */
  width: 100%;
  display: flex;
  background: #fff;
  height: 60px;
  align-items: center;
  padding: 0 15px;
    z-index: 99999999999;
    justify-content: space-between;
    box-sizing: border-box;
}
.search-box {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  display: flex;
  background: #fff;
  min-height: 60px;
  flex-direction: column;
  /* align-items: center; */
  z-index: 99999999999;
}
.search-box-one {
  position: relative;
  width: 85%;
  /* margin-left: 5%; */
  height: 40px;
  border: 2px solid #f2f2f2;
  border-radius: 40px;
  display: flex;
  align-items: center;
}
.input-sty {
  width: 80%;
  margin-left: 2%;
  height: 35px;
  line-height: 35px;
  border: none;
}
.search-icon {
  font-size: 20px;
  margin-left: 10px;
}
.info-box {
  width: 92%;
  margin-left: 4%;
  padding: 5px 0 10px 0;
}
input {
  padding: 0;
}
textarea {
  padding: 0;
}
.box-margin {
  width: 94%;
  margin-left: 3%;
  position: relative;
  top: -30px;
}
.bg-title {
  width: 100%;
}
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.x-box1 .img-list:nth-child(4) {
  margin-right: 0;
}

.x-box1 .img-list:nth-child(8) {
  margin-right: 0;
}
.img-list {
  position: relative;
  width: 23%;
  height: 77px;
  margin-bottom: 8px;
  margin-right: 2%;
}
.dele {
  width: 22px;
  height: 22px;
  position: absolute;
  top: -7px;
  right: -7px;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
}
.add-img1 {
  position: relative;
  width: 23%;
  display: flex;
  align-items: center;
  height: 77px;
  margin-bottom: 7px;
}

.file {
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #1e88c7;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
  width: 100%;
  height: 87px;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file input {
  width: 100%;
  height: 87px;
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}

.file:hover {
  color: #004974;
  text-decoration: none;
}

.img-size1 {
  width: 20px;
  height: 20px;
}
.box {
  height: 100%;
  position: relative;
  background: #eef7f8;
}
.bar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.box-item:last-child {
  border-bottom: none;
}
.share-url {
  width: 92%;
  margin-left: 4%;
  height: 49px;
  background: #079bab;
  line-height: 49px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}
.share-url1 {
  width: 92%;
  margin-left: 4%;
  height: 49px;
  background: #f2f2f2;
  line-height: 49px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}
.content-box {
  width: 100%;
  /* margin-top: 9px; */
  background: #fff;
  padding-bottom: 15px;
  border-radius: 10px;
}
.box-item {
  height: 77px;
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
}
.box-item2 {
  height: 121px;
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 1px solid #f2f2f2; */
}
.box-item21 {
  height: 121px;
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 1px solid #f2f2f2; */
}
.box-item3 {
  /* height: 121px; */
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 2px solid #f2f2f2; */
}
.box-item4 {
  /* height: 121px; */
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 2px solid #f2f2f2; */
  background: rgba(236, 165, 48, 0.16);
  padding: 10px;
  border-radius: 5px;
  color: #eca530;
}
.box-item6 {
  /* height: 121px; */
  position: absolute;
  left: 0;
  top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 2px solid #f2f2f2; */
  background: rgba(236, 165, 48, 0.76);
  padding: 5px 10px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  color: #fff;
}
.box-item3 > div:nth-child(1) {
  font-size: 14px;
  color: #4b4d4d;
  margin-top: 15px;
  margin-bottom: 10px;
}
.box-item3 > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-item2 > div:nth-child(1) {
  font-size: 14px;
  color: #4b4d4d;
}
.box-item2 > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-item > div:nth-child(1) {
  font-size: 16px;
  color: #4b4d4d;
}
.box-item > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-item1 {
  height: 77px;
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
}
.box-item1 > div:nth-child(1) {
  font-size: 16px;
  color: #4b4d4d;
}
.box-item1 > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}
.btm-icon {
  width: 12px;
}
.title {
  font-size: 15px;
  color: #c0c0c0;
  margin-top: 8px;
}
.user-type {
  width: 30%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}
.redbtn-icon {
  width: 18px;
  margin-right: 8px;
}
.input-gh {
  width: 80%;
  border: none;
  margin-top: 8px;
  font-size: 15px;
}
.input-xq {
  width: 100%;
  border: none;
  margin-top: 8px;
  height: 66px;
  font-size: 15px;
  resize: none;
}
input::placeholder {
  color: #c0c0c0;
}
textarea::placeholder {
  color: #c0c0c0;
}
.list-item {
  margin-bottom: 10px;
}
.leftbtn {
  display: flex;
  align-items: center;
}
.gzxqd {
  height: 26.5px;
  border: 1px solid #079bab;
  color: #079bab;
  line-height: 26.5px;
  font-size: 12px;
  text-align: center;
  padding: 0 10px;
  border-radius: 26px;
}
.gdt {
    width:90%;margin-left:5%;overflow-y: scroll;
}
.gdt::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.address-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
}
.next {
  width:100%;
  height: 39px;
  background: #2D83E8;
  margin-top: 20px;;
  border-radius: 40px;
  text-align: center;
  line-height: 39px;
  color: #fff;
  font-size: 16px;;
  margin-bottom: 10px;;
}
</style>
